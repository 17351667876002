import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { compose } from "recompose";
import {
  useAuthUser,
  withAuthorization,
  withEmailVerification,
} from "../../components/Session";
import * as ROLES from "../../constants/roles";
import Layout from "../../components/Layout";
import "react-toastify/dist/ReactToastify.css";

function PushNotificationsBase() {
  const authUser = useAuthUser();
  const [loading, setLoading] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);
  const [value, setValue] = React.useState({ body: "", title: "" });

  const handleChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.value });
  };

  const handleCancel = () => setConfirm(false);
  const handleConfirm = () => setConfirm(true);
  const handleSend = async () => {
    try {
      setLoading(true);

      const payload = JSON.stringify({
        body: value.body,
        title: value.title,
        uid: authUser.uid,
      });

      const response = await fetch(
        "/.netlify/functions/send-push-notification",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: payload,
        }
      );

      if (response.ok) {
        toast.success(
          <span className="toast-text">
            Push notification sent successfully
          </span>,
          {
            position: "bottom-center",
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            closeButton: false,
          }
        );
      } else {
        toast.error("Error sending push notification");
      }
    } catch {
      toast.error("Error sending push notification");
    } finally {
      setConfirm(false);
      setValue({ body: "", title: "" });
      setLoading(false);
    }
  };

  return (
    <section className="section">
      <div className="container content">
        <div className="columns">
          <div className="column is-12">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              Push Notifications
            </h1>
            {loading && <h2>Sending...</h2>}
            {!loading && (
              <div>
                <label htmlFor="title">
                  <strong>Title</strong> ({value.title.length} / 65)
                </label>
                <div>
                  <input
                    id="title"
                    name="title"
                    value={value.title}
                    onChange={handleChange}
                    onBlur={handleChange}
                    placeholder="Notification Title"
                    maxLength={65}
                  />
                </div>

                <label htmlFor="body">
                  <strong>Body</strong> ({value.body.length} / 240)
                </label>
                <div>
                  <textarea
                    id="body"
                    name="body"
                    value={value.body}
                    onChange={handleChange}
                    onBlur={handleChange}
                    rows={20}
                    cols={60}
                    maxLength={240}
                  />
                </div>
                <button
                  disabled={value.title === "" && value.body === ""}
                  onClick={confirm ? handleCancel : handleConfirm}
                >
                  {confirm ? "Cancel" : "Send"}
                </button>
                {confirm && <button onClick={handleSend}>Confirm Send</button>}
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];

const PushNotifications = compose(
  withEmailVerification,
  withAuthorization(condition)
)(PushNotificationsBase);

const PushNotificationsFinal = () => (
  <Layout>
    <PushNotifications />
  </Layout>
);

export default PushNotificationsFinal;
